import { Avatar, AvatarBadge, Box, HStack, Stack, Text, VStack } from '@chakra-ui/react'

export const EmailMessage = (props) => {
  const { recipient, image, timestamp, body, subject } = props?.data
  return (
    <HStack
      align="flex-start"
      gap="3"
      px="4"
      py="3"
      _hover={{
        bg: 'bg.muted',
      }}
      rounded="md"
    >
      <Box pt="1">
        <Avatar size="sm" src={image} name={recipient}>
          <AvatarBadge />
        </Avatar>
      </Box>
   
      <Stack spacing="0" fontSize="sm" flex="1" isTruncated>
      <Text fontWeight="medium" isTruncated>
          {subject}
        </Text>
        <VStack justifyContent={'start'} alignItems={'start'} spacing="1">
          <Text fontWeight="medium" flex="1">
            {recipient}
          </Text>
          <Text color="fg.subtle" fontSize="xs">
            {new Date(timestamp).toLocaleString()}
          </Text>
        </VStack>
       
        <Text color="fg.subtle" isTruncated>
          {body}
        </Text>
      </Stack>
    </HStack>
  )
}
