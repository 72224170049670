import { Icon } from "@chakra-ui/react";
import styles from './IconStyles.module.css';
import { DateVariantStatus } from "@utilities/dateWrapperStatus";
import { BsCalendar2XFill, BsFillCalendar2CheckFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa";


const DueDateIcon = ({ animated = false, stage, due, colorMode = 'light', ...rest }) => {
    const { variant, status, diffDays } = DateVariantStatus(due, colorMode);
    const className = animated ? styles.interm : ''

    const getIconByDueDate = () => {
        if (stage === 'completed') {
            return BsFillCalendar2CheckFill
        }
        if (status && typeof status === 'string')
        switch (status.toLowerCase()) {
            case 'success':
                return BsFillCalendar2CheckFill
            case 'warning':
                return FaClock
            default:
                return BsCalendar2XFill
        }
        return (BsCalendar2XFill)
    }

    return <Icon className={className} as={getIconByDueDate()} color={stage === 'completed' ? 'green' : variant} boxSize={30} {...rest} />
}

export default DueDateIcon;
