import { Box, Container, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import {

  Flex,
  Text,
  Stack,

} from '@chakra-ui/react'

import { IoAnalyticsSharp } from 'react-icons/io5'

import WorkstationImage from '@images/workstation.svg'
import { GrDocument } from "react-icons/gr";
import { WorkstationForm } from "@layouts/workstation/WorkstationFomr";
import PageNavbarHeader from "@components/navbar/PageNavbar";
import SearchForm from "@components/search/SearchForm";
import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tokenConfig } from "@redux/actions/auth-actions/tokenConfig";
import axios from "axios";
import WorkstationCard from "@components/cards/WorkstationCard";


const WorkstationPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const [workstations, setWorkstations] = useState([])
  const [presets, setPresets] = useState({
    department: null,
    area: {
      row: null,
      column: null
    }
  })
  const { department_id, department_name, row, column } = useParams()



  const handleFetchWorkstations = async () => {
    setLoading(true)
    //fetch workstations
    try {
      const token = await tokenConfig({
        action: 'all'
      })
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/workstation`, token)
      setWorkstations(response.data?.workstations)

    } catch (error) {
      console.log(error)
      toast.error('Error fetching workstations. Please try again in a moment')

    }
    setLoading(false)
  }


  useEffect(() => {
    handleFetchWorkstations()
  }, [])



  useEffect(() => {

    setPresets({
      ...presets,
      department: {
        _id: department_id || null,
        id: department_name || null,
      },
      area: {
        row: row || null,
        column: column || null
      }
    })


    //if any of this params are present, we will set the presets and update the url to the base url
    //then open the modal and pass the presets
    if (department_id || (row && column)) {
      window.history.pushState({}, null, '/workstations')
    }


  }, [department_id, row, column])

  useEffect(() => {
    if (presets?.department?._id || (presets?.area?.row && presets?.area?.column)) {
      onOpen()
    }
  }, [presets])

  return (<Box minH={'100vh'}>

    <Helmet>
      <title>Workstation</title>
      <meta name="description" content="Workstation Page" />
      <meta name="keywords" content="Workstation, Workstation Page" />
      <meta name="author" content="UPM" />
    </Helmet>
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Add Workstation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <WorkstationForm onCloseForm={onClose} preset={presets} />
        </ModalBody>
      </ModalContent>
    </Modal>


    <Box position={'sticky'} top={0} zIndex={10}>
      <PageNavbarHeader
        title={'Workstations'}
        actions={[{ id: 'new_workstation_action', title: 'NEW WORKSTATION', color: 'green', icon: <AddIcon />, action: onOpen }]}
        search={<SearchForm isLoading={loading} />}
      />


      <Container maxW="9xl" py={{ base: '16', md: '24' }}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3}} spacing={10}>
          {workstations.map((workstation, index) => <WorkstationCard key={index} item={workstation} />)}
        </SimpleGrid>
      </Container>

    </Box>

  </Box>)
}

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  )
}

export default WorkstationPage;