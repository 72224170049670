
export const P_PANEL = 'panel';
export const P_ACCY = 'accy';
export const P_BOX = 'box';
export const P_GLOVEBOX = 'glovebox';
export const P_LABEL = 'label';
export const P_SWITCH = 'switch';
export const P_CAP = 'cap';
export const P_ACCESSORY = 'accessory';
export const P_MATERIAL = 'material';
export const P_HARNESS = 'harness';
export const P_CONNECTOR = 'connector';
export const P_BREAKER = 'breaker';
export const P_CABLE = 'cable';
export const P_WIRE = 'wire';
export const P_KIT = 'kit';
const partTypes = [P_PANEL, P_CABLE,P_WIRE, P_ACCY, P_BOX,P_GLOVEBOX , P_LABEL, P_SWITCH, P_CAP, P_ACCESSORY, P_MATERIAL, P_HARNESS, P_CONNECTOR, P_BREAKER, P_KIT];
export default partTypes;