import {
    Avatar,
    AvatarBadge,
    Box,
    Button,
    Divider,
    HStack,
    Skeleton,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import { fetchDepartments } from '@redux/actions/department-actions/fetchDepartmentsAction';
import { useEffect, useState } from 'react'
import { BsArrowClockwise } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


export const DepartmentSelectList = ({ onSelect = () => { } }) => {
    const [selected, setSelected] = useState(null)
    const { departments = [], loading } = useSelector(state => state.departmenttt);
    const dispatch = useDispatch()
    useEffect(() => {
        handleRefreshDepartments()
    }, [dispatch])


    const handleRefreshDepartments = () => {
        if (loading)
            return;
        toast.promise(dispatch(fetchDepartments()), {   // eslint-disable-line
            loading: 'Fetching Departments...',
            error: 'Something went wrong getting the deparment list'
        })
    }
    return (<Box rounded={'lg'} m={4}>
        <Skeleton isLoaded={!loading}>
            <Stack divider={<StackDivider />} spacing="2">

                {departments.map((department) => (
                    <Stack bg={selected === department?._id ? "bg.surface" : 'gary.500'} rounded={'md'} key={department.id} fontSize="sm" p="4" spacing="4" cursor={'pointer'} onClick={() => {
                        setSelected(department._id)
                        onSelect(department)
                    }
                    }>
                        <Stack direction="row" justify="space-between" spacing="4">
                            <HStack spacing="3">
                                <Avatar name={department?.id} src={department.photoUrl} boxSize="10">
                                    <AvatarBadge boxSize="4" bg={selected === department?._id ? 'success' : 'none'} />
                                </Avatar>
                                <Box>
                                    <Text fontWeight="medium" color="fg.emphasized">
                                        {department.id}
                                    </Text>
                                    <Text color="fg.muted">...</Text>
                                </Box>
                            </HStack>
                            <Text color="fg.muted">{new Date(department?.createdAt).toLocaleDateString()}</Text>
                        </Stack>
                        <Text
                            color="fg.muted"
                            sx={{
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': '2',
                                overflow: 'hidden',
                                display: '-webkit-box',
                            }}
                        >{department?.description}</Text>

                    </Stack>
                ))}
            </Stack>
        </Skeleton>
        <Divider my={2} />
        <Box w={'full'} display={'flex'} justifyContent={'flex-end'}>
            <Button leftIcon={<BsArrowClockwise />} onClick={handleRefreshDepartments} isLoading={loading} loadingText={'Refreshing Departments'}>Refresh</Button>
        </Box>
    </Box>

    )
}