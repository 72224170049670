import { Tag } from "@chakra-ui/react"
import React from "react"
import { P_ACCESSORY, P_BOX, P_CAP, P_GLOVEBOX, P_HARNESS, P_KIT, P_LABEL, P_PANEL, P_SWITCH } from "src/dictionari/PartTypes"
export const PartTypeTag = ({ type, children, ...props }) => {
  const getColorByType = (type) => {
    if (type && typeof type === 'string')
      switch (type.toLowerCase()) {
        case P_PANEL:
          return 'blue'
        case P_HARNESS:
          return 'green'
        case P_KIT:
          return 'purple'
        case P_ACCESSORY:
          return 'orange'
        case P_BOX:
          return 'pink'
        case P_CAP:
          return 'red'
        case P_LABEL:
          return 'yellow'
        case P_SWITCH:
          return 'teal'
        case P_GLOVEBOX:
          return 'cyan'
        case 'other':
          return 'gray'

        default:
          return 'gray'
      }
    return 'gray'
  }

  return (<Tag p={2} width={'fit-content'} textTransform={'capitalize'} fontSize={{
    base: 'xs',
    sm: 'sm',
    md: 'md',

  }} rounded={'md'} fontWeight={'bold'} variant={'outline'}  {...props} colorScheme={getColorByType(type)}>
    {children && children.length > 0 ? children : type}
  </Tag>)
}

