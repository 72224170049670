import { HStack, Icon, Text, Tooltip, useColorModeValue } from "@chakra-ui/react"
import { BiLinkExternal } from "react-icons/bi"
import { RiArrowRightUpFill } from "react-icons/ri"
import { Link } from "react-router-dom"



export const TooltipLink = ({ href = '#', label, isExternal = false, tooltipPorps = {}, textProps = {},containerProps={}, children }) => {
  return (<Tooltip openDelay={1500} placement='top-end' {...tooltipPorps} label={label}>
    {/*If the link is external  open it in a new tab else open it in the same tab*/}
    <Link to={href} target={isExternal ? '_blank' : '_self'}>
      <HStack
        width={'fit-content'}
        {...containerProps}
        transition={'all .4s ease'}
        _hover={{
          '.link-text,.link-icon': {
            color: 'brand.500',
            fontWeight: 'bold',
          },

          borderBottom: '1px solid',
          borderColor: 'brand.500',
          borderBottomStyle: 'dashed',
        }
        }
      
        fontSize="sm"
        fontWeight="medium"
        {...textProps}
        color={useColorModeValue('gray.600', 'gray.400')}
        >
          {children}
        <Icon transition={'all .4s ease'} className='link-icon' as={isExternal?BiLinkExternal:RiArrowRightUpFill} w={4} h={4} />
      </HStack>
    </Link>
  </Tooltip>)
}