


import ScrollToTop from '@components/ScrollToTop';
import { toast, ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APPRoute } from './APPRoutes';
import { Suspense, lazy, useEffect } from 'react';
import GeneralSpinner from '@components/GeneralSpinner';
import { HelmetProvider } from 'react-helmet-async';
import { getMessaging, onMessage } from 'firebase/messaging';
import { app } from 'src';
import { useWithSound } from './hooks/useWithSound';
import scanner_sound from '@assets/sound/message.mp3';
import { useNavigate } from 'react-router-dom';
import { TooltipLink } from '@components/link/TooltipLink';

const MainFooter = lazy(() => import('@components/MainFooter'))

function App() {
    const { playSound: playScanned } = useWithSound(scanner_sound)
    const navigate = useNavigate();

    const handleSafeNavigate = (path) => {
        //verify if the path is safe and belongs to the app
        //if not redirect to home
        if (path.includes('http') || path.includes('https') || path.includes('://') || path.includes('www') || path.includes('com') || path.includes('net') || path.includes('org')) {
            return navigate('/');

        }
        //check the path does not contain any of the abo
        return navigate(path);
    }


    useEffect(() => {
        const messaging = getMessaging(app);

        onMessage(messaging, (payload) => {
            try {
                playScanned();
            } catch (error) {
                console.error(error);
            }
            toast.info(({ closeToast }) => {
                return (<div>
                    <h2>{payload?.notification?.title}</h2>
                    <p>{payload?.notification?.body}</p>
                    {payload?.data?.link && <button onClick={() => {
                        handleSafeNavigate(payload?.data?.link)
                        closeToast();
                    }
                    }>Open</button>}
                    {payload?.data?.external_link && <TooltipLink href={payload?.data?.external_link} label='Open Link' isExternal >
                        <p>Open Link</p>
                    </TooltipLink>}
                </div>)
            }, {
                position: "bottom-right",
                //prevent auto close
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // ...
        });
    }, [])




    return (<HelmetProvider>


        <ScrollToTop />
        <ToastContainer
            limit={8}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
        />
        <APPRoute />

        <Suspense fallback={<GeneralSpinner />}>
            <MainFooter />
        </Suspense>
    </HelmetProvider>)

}

export default App;
