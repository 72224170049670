import { Box, useColorModeValue} from '@chakra-ui/react'


export const Card = ({ leftColor = null, ...props }) => {
  
  return (<Box
    borderLeft={!!leftColor ? `8px solid` : '4px solid transparent'}
    borderColor={leftColor}
    maxW="8xl"
    w="full"
    mx="auto"

    bg={useColorModeValue('gray.100', 'gray.700')}
    rounded={{
      base:'lg',
      md: 'xl',
    }}
    
    padding="5"
    _hover={{
      shadow: 'md',
      bg:useColorModeValue(
        'white',
        'gray.800'
      )
    
    }}
   
    px={{
      base: '6',
      md: '8',
    }}
    {...props}
  />
  )
}