import { Box, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react'
import { Button, } from "react-bootstrap";

export const StaticModal = ({ show, header, body, handleClose = () => console.log('N/A'), size = 'xl', children, ...modalProps }) => {
    return (
        <Modal
            isCentered
            isOpen={show}
            backdropFilter='auto'
            backdropBlur='22px'
            closeOnOverlayClick={false}
            onClose={handleClose}
            motionPreset='slideInRight'
            dialogClassName="modal-80w"
            size={size}
            {...modalProps}
            >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    {header}
                </ModalHeader>
                <Box>
                    {body}
                </Box>
                <Box>
                    {children}
                </Box>
            </ModalContent>
            <ModalFooter>
                <Box mr={20} display={'flex'} justifyContent={'flex-start'}>
                    <Button size='md' variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button>
                </Box>
            </ModalFooter>
        </Modal>

    );
}
