
/*@lrcorona2014
**
*
*/



/**
 * 
 * @param {*} date 
 * @param {Objec} marks
 * @description
 * Good as g default 20 Days
 * On Time as ot default 10 Days
 * Almost There as at default 5 
 * Running Late as rl default 1  
 * @returns 
 */
export const DateVariantStatus = (date, colorMode = 'light', marks = { good: 45, onTime: 20, almostThere: 15, runnigLate: 10 }) => {


    if (!!date && typeof date === 'string') {
        try {

            const currentDate = new Date();
            const targetDate = new Date(date);
            const diffTime = targetDate - currentDate;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const getVariant = () => {

                switch (true) {
                    case diffDays > marks.good:
                        return { variant: colorMode !== 'dark' ? 'green.300' : 'green.600', status: 'success' };
                    case diffDays <= marks.good && diffDays > marks.onTime:
                        return { variant: colorMode !== 'dark' ? 'blue.300' : 'blue.500', status: 'success' };
                    case diffDays <= marks.onTime && diffDays > marks.almostThere:
                        return { variant: colorMode !== 'dark' ? 'yellow.300' : 'yellow.500', status: 'warning' };
                    case diffDays <= marks.almostThere && diffDays > marks.runnigLate:
                        return { variant: colorMode !== 'dark' ? 'pink.300' : 'pink.500', status: 'danger' };
                    default:
                        return { variant: colorMode !== 'dark' ? 'red.300' : 'red.500', status: 'danger' };
                }
            }
            return ({ ...getVariant(), diffDays: diffDays })
        } catch (error) {
            return ({ variant: 'blackAlpha.700', diffDays: -1 })
        }
    }else
    return ({ variant: 'black', diffDays: -1 })
}