import React, { useEffect, useState } from 'react'
import { Badge, Box, HStack, Icon, useColorMode } from '@chakra-ui/react'
import { tokenConfig } from '@redux/actions/auth-actions/tokenConfig'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Check2Circle } from 'react-bootstrap-icons'



const WorkstationGridArea = ({ wks_id = null, area, columns = 1, onSelect, isInUse = false,isSelected=false, ...props }) => {
    const [areaConfig, setAreaConfig] = useState({})
    const { colorMode } = useColorMode()
    const [workstation, setWorkstation] = useState({})

    const handleGetAreaConfig = () => {
        //The row equation is the total area divided by the columns if the remainder is 0 then the row is the total area divided by the columns else the row is the total area divided by the columns plus 1
        const row = area % columns === 0 ? area / columns : Math.floor(area / columns) + 1
        const column = area % columns === 0 ? columns : area % columns
        setAreaConfig({ row, column })
    }

    const handleFecthWorkstation = async () => {
        try {
            if (!wks_id) return
            const token = await tokenConfig({ action: 'details', workstation: wks_id })
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/workstation`, token)
            setWorkstation(response.data.workstation)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        handleFecthWorkstation()
    }, [wks_id])

    useEffect(() => {
        handleGetAreaConfig()
    }
        , [area, columns])

    return (<Box
        onClick={() => onSelect(areaConfig)}
        p={2}
        shadow={'md'}
        position={'relative'}
        borderRadius={'md'}
        bg={colorMode === 'light' ? isInUse ? 'blue.200' : 'bg.surface' : isInUse ? 'blue.800' : 'bg.surfaceDark'}
        width={'full'}
        cursor={'pointer'}
        {...props}
    >
        {isSelected&&<Icon position={'absolute'} top={2} right={2} boxSize={5} as={Check2Circle} />}

        <HStack>
            
            <Badge variant='outline' colorScheme={isInUse ? 'blue' : 'gray'}>
                {workstation?.label||wks_id||area}
            </Badge>
        </HStack>
    </Box>)
}


export default WorkstationGridArea;