import {
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stack,
    Text,
} from '@chakra-ui/react'
import { BsChatTextFill, BsMicFill, BsPaperclip, BsPinAngleFill } from 'react-icons/bs'
import { ChatGroupHeader } from '@components/messages/ChatGroupHeader'

import { SearchInput } from '@components/messages/SearchInput'
import { EmailMessage } from '@components/messages/EmailMessage'
import { BiSync } from 'react-icons/bi'
export const ComunicationCenterLayout = () => {
  
const emails = [
    {
        recipient: 'Jane Cooper',
        image: 'https://bit.ly/3jR6ZJ5',
        timestamp: '2021-06-12T12:45:00Z',
        subject: 'New leads',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget sapien et risus ultricies lacinia. Ut ut orci nec justo fermentum suscipit. Nullam sit amet semper odio. Sed at est nec purus bibendum lacinia. Nulla facilisi. Donec nec felis nec nulla tristique ultricies   ',
    },
    {
        recipient: 'John Doe',
        image: 'https://bit.ly/3jR6ZJ5',
        timestamp: '2021-06-12T12:45:00Z',
        subject: 'Meeting at 3pm',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget sapien et risus ultricies lacinia. Ut ut orci nec justo fermentum suscipit. Nullam sit amet semper odio. Sed at est nec purus bibendum lacinia. Nulla facilisi. Donec nec felis nec nulla tristique ultricies   ',
    },
    {
        recipient: 'Jane Doe',
        image: 'https://bit.ly/3jR6ZJ5',
        timestamp: '2021-06-12T12:45:00Z',
        subject: 'New leads',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget sapien et risus ultricies lacinia. Ut ut orci nec justo fermentum suscipit. Nullam sit amet semper odio. Sed at est nec purus bibendum lacinia. Nulla facilisi. Donec nec felis nec nulla tristique ultricies   ',
    },
    {
        recipient: 'John Cooper',
        image: 'https://bit.ly/3jR6ZJ5',
        timestamp: '2021-06-12T12:45:00Z',
        subject: 'Meeting at 3pm',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget sapien et risus ultricies lacinia. Ut ut orci nec justo fermentum suscipit. Nullam sit amet semper odio. Sed at est nec purus bibendum lacinia. Nulla facilisi. Donec nec felis nec nulla tristique ultricies   ',
    },
]
const group = { members: [
        { name: 'Jane Cooper', image: 'https://bit.ly/3jR6ZJ5' },
        { name: 'John Doe', image: 'https://bit.ly/3jR6ZJ5' },
        { name: 'Jane Doe', image: 'https://bit.ly/3jR6ZJ5' },
        { name: 'John Cooper', image: 'https://bit.ly/3jR6ZJ5' },
] }
    return (<Box  minH={'100vh'}>
        <Flex overflow="hidden" minH={'50vh'}>
            <Stack spacing="4" width="320px" borderEndWidth="1px" pt="6" overflowY={'auto'} maxH={'80vh'}>
                <HStack px="5" justifyContent={'space-between'}>
                    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'end'} >
                        <Badge fontSize={'md'}>{!!emails ? emails?.length : 0}</Badge>
                        <Text fontSize="lg"  fontWeight="medium" justifyItems={'end'} >
                            Emails
                        </Text>
                    </Box>

                    <IconButton size="sm" aria-label="Refresh emails" variant={'ghost'} icon={<BiSync size={25} />} onClick={()=>alert('(-*-)')} />
                </HStack>

                <Box px="5">
                    <SearchInput />
                </Box>

                <Stack mt="2" spacing="4" flex="1" overflowY="auto" px="5" pb="5">
                    <Stack mt="2" spacing="4">
                        <ChatGroupHeader icon={BsPinAngleFill}>Pinned</ChatGroupHeader>
                        <Stack spacing="0" mx="-4">
                            {/*emails.map((email, index) => (
                                <EmailMessage key={index} data={email} />
                            ))*/}
                        </Stack>
                    </Stack>

                    <Stack mt="2" spacing="4">
                        <ChatGroupHeader icon={BsChatTextFill}>Emails</ChatGroupHeader>
                        <Stack spacing="0" mx="-4">
                            {emails.map((email, index) => (
                                <EmailMessage key={`${index}-private`} isPrivate data={email} />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Flex direction="column" flex="1" maxW={'3xl'} m={2}>
                <Flex borderBottomWidth="1px" px="4" py="4">
                    <HStack spacing="4" flex="1">
                        <Input placeholder="Search in this group" />
                        <Button size="sm">Add Member</Button>
                    </HStack>

                    <AvatarGroup size="sm">
                        {group?.members?.map((member) => (
                            <Avatar name={member?.name} src={member?.image} />
                        ))}
                    </AvatarGroup>
                </Flex>

                <Box flex="1" overflow="auto" px="5" py="4">
                    <Box borderWidth="2px" borderStyle="dashed" height="full" />
                </Box>

                <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">

                    <InputGroup>
                        <InputLeftElement>
                            <IconButton
                                size="sm"
                                aria-label="Record voice"
                                icon={<Icon as={BsMicFill} fontSize="md!" />}
                                variant="ghost"
                            />
                        </InputLeftElement>
                        <Input placeholder="Send a message..." fontSize="sm" />
                        <InputRightElement>
                            <IconButton
                                size="sm"
                                variant="ghost"
                                aria-label="Send message"
                                icon={<Icon as={BsPaperclip} fontSize="md!" />}
                            />
                        </InputRightElement>
                    </InputGroup>

                </Box>
            </Flex>
        </Flex>
        </Box>)
}