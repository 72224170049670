import {
  FETCH_DEPARTMENTS_STARTED,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE
} from "../types";
import { tokenConfig } from "../auth-actions/tokenConfig";
import axios from "axios";

export const fetchDepartments = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(fetchDepartmentsStarted());
      const token = await tokenConfig();
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/department`, token)
        .then(res => {
          dispatch(fetchDepartmentsSuccess(res.data.list));
          resolve('Departments fetched successfully');
        })
        .catch(err => {
          console.log(err)
          dispatch(fetchDepartmentsFailure(err.message));
          reject('Departments fetching failed');
        });
    } catch (error) {
      console.log(error)
      reject('Departments fetching failed');
      dispatch(fetchDepartmentsFailure('Somthing went  wrong..'));
    }


  });
};

const fetchDepartmentsStarted = () => {
  return {
    type: FETCH_DEPARTMENTS_STARTED
  };
};

const fetchDepartmentsSuccess = departments => {
  return {
    type: FETCH_DEPARTMENTS_SUCCESS,
    payload: {
      departments
    }
  };
};

const fetchDepartmentsFailure = error => {
  return {
    type: FETCH_DEPARTMENTS_FAILURE,
    payload: {
      error
    }
  };
};
