import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Stack,
    Tag,
    Text,
    useColorMode,
    useColorModeValue,
    Wrap,
} from '@chakra-ui/react'
import { Card } from './Card'
import { FaArrowRight } from 'react-icons/fa'
import DueDateIcon from '@components/icons/DueDateIcon'
import { Link } from 'react-router-dom'
import { DateVariantStatus } from '@utilities/dateWrapperStatus'
import { PartTypeTag } from '@layouts/part/PartTypeTag'
import { useEffect, useState } from 'react'
import { tokenConfig } from '@redux/actions/auth-actions/tokenConfig'
import axios from 'axios'


const WorkstationCard = ({ item, onSelect = () => { } }) => {
    const { colorMode } = useColorMode();
    const { variant } = DateVariantStatus(item?.due, colorMode);
    const isCompleted = item?.status?.stage === 'completed';
    const backgroundColor = isCompleted ? colorMode === 'light' ? 'green.200' : 'blue.900' : colorMode === 'light' ? 'white' : 'gray.700';
    const [traveler, setTraveler] = useState(null)

    const handleFectCurrentTraveler = async () => {
        try {
            const token = await tokenConfig({
                action: "find",
                _id: item?.current_job?.traveler
            })
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/traveler/select`, token)
            setTraveler(response.data.traveler)
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        if (!item?.current_job?.traveler) return;
        handleFectCurrentTraveler()
    }
        , [item?.current_job?.traveler])


    return (<Card shadow='2xl' position='relative' leftColor={!isCompleted ? variant : ''} bg={backgroundColor}>
        <HStack position={'absolute'} left={5} top={-5} shadow={'sm'} bg={colorMode === 'light' ? 'gray.300' : 'blackAlpha.700'} p={2} borderRadius='lg' >
            <Badge variant='outline' colorScheme='blue'>
                {item?.department?.id}
            </Badge>
            {item?.label && <Badge variant='outline' colorScheme={item?.isFullInTravelers ? colorMode === 'light' ? "blue" : "green" : 'red'}>
                {item?.label}
            </Badge>}

        </HStack>
        <Flex justifyContent={'flex-end'}>
            <Link to={`/payloads/${item?._id}`}>
                <Button h={'fit-content'} variant={'ghost'} colorScheme={'whatsapp'} size="sm" fontSize="md" rightIcon={<FaArrowRight />}>
                    Details
                </Button>
            </Link>
        </Flex>
        <Container>
            <Stack spacing={1} mt={2}>
                <HStack justifyContent={'space-between'}>
                    <Heading as="h6" size="sm">
                        {item?.name}
                    </Heading>
                    <PartTypeTag type={item?.type} />
                </HStack>
                <Divider />
                <Stack spacing={1}>
                    <Text as="h6" fontSize="sm" color={'gray.500'}>
                        {item?.description}
                    </Text>
                    <Text as="h6" fontSize="sm" color={'gray.500'}>
                        {item?.due}
                    </Text>
                </Stack>
            </Stack>
        </Container>

        <Wrap mt={2} className='card-footer' bg={backgroundColor} >
            <Text as="h6" m={0} fontSize="sm" color={'gray.500'} textAlign={'center'}>
                Column: {item?.area?.column}
            </Text>
            <Text as="h6" m={0} fontSize="sm" color={'gray.500'} textAlign={'center'}>
                Row: {item?.area?.row}
            </Text>
        </Wrap>

    </Card>)
}

export default WorkstationCard;