import { Box, Button, ButtonGroup, Container, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Select, SimpleGrid, Stack, useDisclosure } from "@chakra-ui/react"
import { DepartmentSelectList } from "@components/department/DepartmentSelectList"
import GeneralSpinner from "@components/GeneralSpinner"
import { StaticModal } from "@components/modal/StaticModal"
import { tokenConfig } from "@redux/actions/auth-actions/tokenConfig"
import axios from "axios"
import { Suspense, useEffect, useState } from "react"
import { MdSearch } from "react-icons/md"
import { RiExchangeLine } from "react-icons/ri"
import { toast } from "react-toastify"
import WorkstationGridArea from "./WorkstationGridArea"





export const WorkstationForm = ({ onChange = () => { }, onCloseForm = () => { }, preset, formProps = {
    isRequired: true,
    isInvalid: false,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDir: 'column',
} }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [workstation, setWorkstation] = useState(preset)
    const [department_areas, setDepartmentAreas] = useState([])
    const [department_area_config, setDepartmentAreaConfig] = useState({})
    const [saving, setSaving] = useState(false)


    const handleChange = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        const { name, value } = evt.target
        setWorkstation({ ...workstation, [name]: value })
    }



    const handleSelectDepartment = (department) => {
        setWorkstation({ ...workstation, department })
        handleFetchDepartmentAreas(department._id)
        onClose()
    }


    const handleFetchDepartmentAreas = async (department_id) => {
        try {
            const token = await tokenConfig({ action: 'workstation_areas', department: department_id })
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/workstation/area`, token)
            setDepartmentAreas(response.data.areas)
            setDepartmentAreaConfig(response.data.department_area_config)

        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleCreateWorkstation = async () => {
        try {
            const token = await tokenConfig({ action: 'create_workstation' })
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/workstation`, workstation, token)
            toast.success(response.data.message)
            onChange(response.data.workstation)
        } catch ({ response, error }) {
            console.log(error)
            toast.error(response?.data?.message)
        }
    }


    const handleSubmit = async () => {
        try {

            if (!workstation?.department?._id) return toast.error('Please select a department')
            if (!workstation?.code) return toast.error('Please set a workstation code')
            if (!workstation?.label) return toast.error('Please set a workstation label')
            if (!workstation?.area?.row) return toast.error('Please set a workstation row')
            if (!workstation?.area?.column) return toast.error('Please set a workstation column')
            toast.info(`Creating workstation ${workstation.label} in ${workstation.department.id}`)
            await handleCreateWorkstation()
        } catch (error) {
            toast.error(error.message)
        }
    }


    const handleSelectArea = (area) => {
        const { row, column } = area
        if (row <= 0 || column <= 0)
            return toast.error('Invalid area')
        if (row > department_area_config.row || column > department_area_config.column)
            return toast.error('Area out of range')
        setWorkstation({ ...workstation, area })



    }


    const handleIsSelected = (area) => {
        //The row equation is the total area divided by the columns if the remainder is 0 then the row is the total area divided by the columns else the row is the total area divided by the columns plus 1
        const columns = department_area_config.column;
        if (!columns) return false
        const row = area % columns === 0 ? area / columns : Math.floor(area / columns) + 1
        const column = area % columns === 0 ? columns : area % columns
        if (!row || !column) return false
        return workstation?.area?.row === row && workstation?.area?.column === column
    }





        useEffect(() => {
            setWorkstation(preset)
            if (preset?.department?._id) {
                handleFetchDepartmentAreas(preset.department._id)
            }
            if (preset?.area?.row && preset?.area?.column) {
                setWorkstation({ ...workstation, area: { row: preset.area.row, column: preset.area.column } })
            }
        }, [preset])


        return (<Container maxW={'sm'} py={12} >

            <Stack m={0} p={0} spacing="5" justifyContent={'flex-start'} alignItems={'flex-start'}{...formProps}>
                {!preset?.department?._id&&<StaticModal
                    show={isOpen}
                    handleClose={onClose}
                    body={<Suspense fallback={<GeneralSpinner />}>
                        <Container>
                            <DepartmentSelectList onSelect={handleSelectDepartment} />
                        </Container>
                    </Suspense>}
                    header={'Workstation Department'}  >
                </StaticModal>}
                <FormControl id="id" isInvalid={!workstation?.department?._id} isRequired  >
                    <FormLabel>Department</FormLabel>
                    <Button
                        justifyContent={'flex-start'}
                        size={'sm'}
                        w={'full'}
                        id="department_filter"
                        variant={'outline'}
                        onClick={onOpen}
                        isDisabled={preset?.department?._id}
                        textTransform={'capitalize'}
                        leftIcon={!workstation?.department?.id ? <MdSearch size={30} /> : <RiExchangeLine size={30} />}
                    >
                        {workstation?.department?.id || 'Search'}
                    </Button>
                    <FormHelperText>
                        {workstation?.department?._id}
                    </FormHelperText>
                    <FormErrorMessage>
                        Please select a department
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="label" isRequired  >
                    <FormLabel>Label</FormLabel>
                    <Input value={workstation?.label} name="label" type="text" maxLength={24} onChange={
                        handleChange
                    } />
                    <FormErrorMessage>
                        Please set a workstation label
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="code" isRequired  >
                    <FormLabel>Code</FormLabel>
                    <Input value={workstation?.code} name="code" type="text" maxLength={24} onChange={
                        handleChange
                    } />
                    <FormErrorMessage>
                        Please set a workstation code
                    </FormErrorMessage>
                </FormControl>
                <Box p={2} shadow={'xl'} borderRadius={'md'} width={'full'}>
                    <Heading size={'xs'} >Workstation Area</Heading>
                    {department_area_config && <SimpleGrid width={'full'} columns={department_area_config.column} gap={2}>
                        {Array.from({ length: department_area_config.row * department_area_config.column }, (_, i) => i + 1).map((area) => {
                            return <WorkstationGridArea key={`area-${area}`} isSelected={handleIsSelected(area)} area={area} columns={department_area_config.column} onSelect={handleSelectArea} />
                        })}
                    </SimpleGrid>}
                    <FormControl mt={5} >
                        <FormLabel>Column</FormLabel>
                        <Select value={workstation?.area?.column} name="area.comlumn" >
                            {Array.from({ length: department_area_config.column }, (_, i) => i + 1).map((col) => {
                                return <option key={`column-${col}`} value={col}>{col}</option>
                            })}

                        </Select>
                        <FormErrorMessage>
                            Please set a workstation column
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl >
                        <FormLabel>Row</FormLabel>
                        <Select value={workstation?.area?.row} name="area?.row" >
                            {Array.from({ length: department_area_config.row }, (_, i) => i + 1).map((row) => {
                                return <option key={`row-${row}`} value={row}>{row}</option>
                            })}
                        </Select>
                        <FormErrorMessage>
                            Please set a workstation row
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <ButtonGroup width={'full'} justifyContent={'space-between'}>
                    <Button onClick={onCloseForm} variant='outline' colorScheme='red' mr={8} >
                        Close
                    </Button>
                    <Button variant='solid' colorScheme="green" onClick={handleSubmit}>Save</Button>

                </ButtonGroup>


            </Stack>

        </Container>)
    }


