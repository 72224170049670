import React, { useEffect, useState } from 'react'
import {
    Badge, Box, Checkbox, CloseButton, Divider, FormControl, FormErrorMessage, FormLabel, HStack,
    IconButton, Input,  Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger,
    RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Stack, Switch, Text, Tooltip, VStack, useColorMode,  useDisclosure,
} from '@chakra-ui/react';

import { FcCalendar, FcGenericSortingAsc, FcGenericSortingDesc, FcNext, FcPrevious } from "react-icons/fc"
import { Field, Form, Formik } from 'formik';
import { useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import copyToClipBoard from '@utilities/copytoClipBoard';
import { FiChevronDown } from 'react-icons/fi';
import { Search2Icon } from '@chakra-ui/icons';
import SearchBar from './SearchBar';
 const SearchForm = (
    {
        isLoading = false,
        handleSearch = () => { },

        filterAndSort = {},
        ...rest
    }
) => {

    const [isSearching, setIsearching] = useState(false)
    const maxLength = 25
    const [value, setValue] = useState('');
    const [filter, setFilter] = useState({})
    const [isReady, setIsready] = useState(false)
    const { isOpen: allowSortAndFilter, onClose: onCloseSortAndFilter, onOpen: onOpenSortAndFilter } = useDisclosure()
    const { colorMode } = useColorMode()

   

    useEffect(() => {
        const newFilter = {}
        try {
            if (Array.isArray(filterAndSort)) {
                filterAndSort.forEach((field, index) => {
                    switch (typeof field) {
                        case 'string':
                            const ref = field.split(".");
                            const path = field.split(".");//convert string field in array by .
                            path.reduce(function (newFilter, path) {
                                const isHead = ref.indexOf(path) === 0;
                                const islastNode = ref.indexOf(path) === ref.length - 1
                                if (isHead)
                                    return Object.assign(newFilter, { [path]: islastNode ? { ...newFilter?.[path], sort: 0, filter: 0, refPath: field } : { ...newFilter[path] } })
                                if (!islastNode)
                                    return newFilter[path] = { ...newFilter[path] }
                                return newFilter[path] = { ...newFilter?.[path], sort: 0, filter: 0, refPath: field }
                            }, newFilter)
                            break;
                        case 'object':
                            const obje_ref = field.path.split(".");
                            const obj_path = field.path.split(".");//convert string field in array by .
                            obj_path.reduce(function (newFilter, obj_path) {
                                const islastNode = obje_ref.indexOf(obj_path) === obje_ref.length - 1
                                return newFilter[obj_path] = islastNode ? { ...newFilter?.[obj_path], sort: field?.sort || 0, filter: field?.filter || 0, type: field?.type, refPath: field?.path || field } : { ...newFilter?.[obj_path] }
                            }, newFilter)
                            break;
                        default:
                            break;
                    }

                });

                setFilter(newFilter)
                setIsready(true)
         
            }
        } catch (error) {
            setIsready(false)
        }
    }, [filterAndSort])

    useEffect(() => {
        setIsearching(true)
        const filterBy = []
        const sortBy = {}
        const getDeepSorting = (field) => {
            Object.keys(field).forEach((key, index) => {
                const { refPath = null, sort, filter } = field?.[key] || {}
                if (refPath) {
                    try {
                        if (sort)
                            sortBy[refPath] = sort
                        if (filter)
                            filterBy.push(refPath)
                    }//try
                    catch (error) {

                    }//catch
                }//if
                else if (typeof field[key] === 'object') {
                    return getDeepSorting(field[key])
                }
            });
        }


        const timeOutId = setTimeout(() => {
            getDeepSorting(filter)
            handleSearch({ fields: filterBy, sort: sortBy, value: value })
            setIsearching(false)
        }, 1000);

        return () => clearTimeout(timeOutId);
    }, [value, filter]);



    function assign(obj, prop, value) {
        if (typeof prop === "string")
            prop = prop.split(".");

        if (prop.length > 1) {
            var e = prop.shift();
            assign(obj[e] =
                Object.prototype.toString.call(obj[e]) === "[object Object]"
                    ? obj[e]
                    : {},
                prop,
                value);
        } else
            obj[prop[0]] = value;
    }

    const onToggle = (field, value) => {
        try {
            const newFilter = { ...filter }
            assign(newFilter, field, value)
            setFilter({ ...newFilter })
        } catch (error) {
            //console.log(error)
        }
    }



    return (
        <Box rounded={'2xl'}>

            <Box rounded={'sm'} >
                <SearchBar
                isLoading={isSearching||isLoading}
              
                autoComplete={'off'}
                maxLength={maxLength}
                onKeyUpCapture={(evt) => evt.key === "Enter" ? evt.target.select() : null}
                onDoubleClick={evt => {
                    evt.target.select()
                    copyToClipBoard(evt.target.value)
                }}
                onChange={(evt) => {
                    setValue(evt.target.value);
                }}
                value={value}
                >
                      {allowSortAndFilter ? <CloseButton size={'sm'} onClick={onCloseSortAndFilter} />
                            : <IconButton size={'xs'} title='Filter & Sort' variant="unstyled" roundedLeft={0} alignSelf={'flex-end'} icon={<FiChevronDown onClick={onOpenSortAndFilter} size={25} />} />}
                </SearchBar>
              

            </Box>

            <Box {...rest} position={'absolute'} zIndex={999}>
                {allowSortAndFilter && (<Stack spacing={3} my={0}>
                    <Box color={'white'} bg={colorMode === 'light' ? 'blackAlpha.900' : 'gray.900'}
                        rounded={'lg'}
                        p={5}>
                        {isReady && <Box >
                            <ControlList fields={filter} onToggle={onToggle} />
                        </Box>
                        }
                    </Box>
                </Stack>)}
            </Box>
        </Box>


    )
}

export const ControlList = ({ fields = null, onToggle = () => { } }) => {
    const [fieldTree, setFieldTree] = useState([])

    useEffect(() => {
        if (!!fields && typeof fields === 'object') {

            let tree = []
            Object.entries(fields)?.forEach(([parentKey, parentValue]) => {
                if (!!parentKey && parentValue !== undefined) {
                    if (parentValue?.["sort"] === undefined) {

                        tree.push(<Box key={`${parentKey}-root`} borderTop={'1px'}>
                            <Text>{parentKey?.toUpperCase()}</Text>
                            <Box ml={3}>
                                {
                                    Object.keys(parentValue).map(key => {
                                        return (<ControlField
                                            key={`${parentKey}-child-${key}`}
                                            field={{ name: key, value: parentValue[key], id: `${parentKey}.${key}` }}
                                            onToggle={onToggle}
                                        />)
                                    })
                                }
                            </Box>
                        </Box>)

                    }// if has sort property
                    else {

                        tree.unshift(
                            <ControlField key={`${parentKey}-root`}
                                field={{ name: parentKey, value: parentValue, id: parentKey }}
                                onToggle={onToggle}
                            />)

                    }

                }// if name & children
            })
            setFieldTree(tree)
        }
    }, [fields])
    if (fieldTree?.length < 1)
        return null
    return (fieldTree)

}


const ControlField = ({ field = {}, onToggle = () => { } }) => {
    const id = field?.id
    const name = field?.name
    const { sort, filter = 0, type = 'string' } = field?.value || {}

    const icons = [<FcGenericSortingAsc size={25} />, <FcGenericSortingDesc size={25} />]
    return (<Box m={0}>
        <Stack direction={['column', 'row']} alignItems={'center'} spacing={4} justifyContent={'flex-end'} rounded={'md'}>
            <Box >

                <Tooltip label={id} openDelay={500}>
                    <Text justifySelf={'flex-start'} size={'xs'}>{name.toUpperCase()}</Text>
                </Tooltip>
            </Box>
            <HStack>
                {filter && type === 'date' && <DatePickerPopover title={`${name.toUpperCase()} DATE RANGE`} />}
                <FormControl display='flex' alignItems='center'>

                    <Checkbox color='white' fontSize={'sm'} size='sm' id={`${id}-filter`} onChange={() => onToggle(`${id}.filter`, filter ? 0 : 1)} isChecked={filter} mr={2} />
                    <FormLabel color={'white'} fontSize={'sm'} htmlFor={`${id}-filter`} mb='0'>
                        Filter
                    </FormLabel>
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <Switch size={'sm'} isChecked={sort} onChange={() => onToggle(`${id}.sort`, sort ? 0 : 1)} id={`${id}-filter`} mr={2} />
                    <FormLabel color={'white'} fontSize={'sm'} htmlFor={`${id}-filter`} mb='0'>
                        Sort
                    </FormLabel>
                    <IconButton isDisabled={!sort} onClick={() => onToggle(`${id}.sort`, sort * -1)} variant={'ghost'} icon={icons[sort < 0 ? 0 : 1]} />

                </FormControl>

            </HStack>

        </Stack>


    </Box>)
}





function DatePickerPopover({ title }) {
    const initialFocusRef = useRef()
    const format = 'YYYY-MM-DD';
    const defaultGap = [-100, 100]
    const [dateRange, setDateRange] = useState({
        from: new moment().add(defaultGap[0], 'days').format(format),
        to: new moment().add(defaultGap[1], 'days').format(format)
    })



    const handleChangeDateRange = ([min, max], type) => {
        try {
            if (type === 'days') {
                const from = new moment().add(min, 'days').format(format)
                const to = new moment().add(max, 'days').format(format);
                if (moment(dateRange.from).isAfter(moment(dateRange.to))) {
                    toast.error("From date can not be greater than To date", { position: 'top-left', autoClose: 6000 })
                } else {
                    setDateRange({ from: from, to: to })
                }
            } else {
                if (moment(min).isAfter(moment(max))) {
                    toast.error("From date can not be greater than To date", { position: 'top-left', autoClose: 6000 })
                } else {
                    setDateRange({ from: min, to: max })
                }

            }
        } catch (error) {
            //console.log(error)
        }


    }

    return (<Box p={1} maxW={'lg'} >
        <Stack direction={['column']} w={'full'} spacing={5}>
            Date Range
            <VStack>
                <Popover
                    initialFocusRef={initialFocusRef}
                    placement='bottom'
                    closeOnBlur
                >
                    <PopoverTrigger>
                        <IconButton variant={'outline'} icon={<FcCalendar size={25} />} />
                    </PopoverTrigger>
                    <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                        <PopoverHeader pt={4} fontWeight='bold' textAlign={'center'}>
                            {title}
                        </PopoverHeader>
                        <PopoverArrow bg='blue.800' />
                        <PopoverCloseButton />
                        <PopoverBody >
                            <Formik
                                initialValues={{ from: dateRange.from, to: dateRange.to }}>
                                {(props) => (
                                    <Form >
                                        <RangeSlider minStepsBetweenThumbs={1} onChange={([min, max]) => handleChangeDateRange([min, max], 'days')} min={-600} max={600} step={1} aria-label={['min', 'max']} defaultValue={defaultGap}>
                                            <RangeSliderTrack>
                                                <RangeSliderFilledTrack />
                                            </RangeSliderTrack>
                                            <RangeSliderThumb boxSize={6} index={0}>
                                                <Box as={FcNext} size={25} />
                                            </RangeSliderThumb>
                                            <RangeSliderThumb boxSize={6} index={1}>
                                                <Box size={25} as={FcPrevious} />
                                            </RangeSliderThumb>

                                        </RangeSlider>
                                        <Field name='from' >
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.from && form.touched.from}>
                                                    <FormLabel>From</FormLabel>
                                                    <Input {...field} value={dateRange.from} onChange={(evt) => handleChangeDateRange([evt.target.value, dateRange.to], 'dates')} type="date" />
                                                    <FormErrorMessage>{form.errors.from}</FormErrorMessage>
                                                </FormControl>
                                            )
                                            }
                                        </Field>
                                        <Divider></Divider>
                                        <Field name='to'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.to && form.touched.to}>
                                                    <FormLabel>To</FormLabel>
                                                    <Input {...field} type="date" onChange={(evt) => handleChangeDateRange([dateRange.from, evt.target.value], 'dates')} value={dateRange.to} />
                                                    <FormErrorMessage>{form.errors.to}</FormErrorMessage>
                                                </FormControl>
                                            )
                                            }
                                        </Field>

                                    </Form>
                                )}
                            </Formik>
                        </PopoverBody>
                        <PopoverFooter
                            border='0'
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            pb={4}
                        >
                        </PopoverFooter>
                    </PopoverContent>
                </Popover>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'end'} flexFlow={'row wrap'} w={'full'}>
                    <Badge variant={'outline'}><Text m={0} p={0} fontSize={'xs'} fontWeight='bold' >{dateRange.from}</Text></Badge>
                    <Badge variant={'outline'}><Text m={0} p={0} fontSize={'xs'} fontWeight='bold'>{dateRange.to}</Text></Badge>
                </Box>
            </VStack>
        </Stack>
    </Box>

    )
}

export default SearchForm;