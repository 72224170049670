import { Icon } from "@chakra-ui/react";
import { getColorByStatus } from "@utilities/getColorByStatus";
import styles from './IconStyles.module.css';
import { FaCheckDouble } from "react-icons/fa";
import { RiSpyFill } from "react-icons/ri";
import { MdHealthAndSafety, MdLocalShipping, MdOutlineCancel, MdOutlinePause, MdOutlineSettings, MdRocketLaunch, MdSyncProblem } from "react-icons/md";


const StatusIcon = ({ status, animated=true, colorMode = 'light', ...rest }) => {
    const iconColor = getColorByStatus(status, colorMode);
    const getIconByStatus = () => {
        if (status && typeof status === 'string')
            switch (status.toLowerCase()) {
                case 'launched':
                    return ({ as: MdRocketLaunch })
                case 'processing':
                    return ({ as: MdOutlineSettings, className: animated?styles.rotate:'' })
                case 'hold':
                    return ({ as: MdOutlinePause })
                    case 'canceled':
                    return ({ as: MdOutlineCancel })
                case 'disabled':
                    return ({ as: MdOutlineCancel })
                case 'returned':
                    return ({ as: MdSyncProblem })
                case 'qc':
                    return ({ as: RiSpyFill })
                case 'shipped':
                    return ({ as: MdLocalShipping })
                case 'completed':
                    return ({ as: FaCheckDouble})

                default:
                    return ({ as: MdHealthAndSafety })

            }
        return (MdHealthAndSafety)
    }

    return <Icon {...getIconByStatus()} color={iconColor} w={30} h={30} {...rest} />
}

export default StatusIcon;