import { Tag, Text } from "@chakra-ui/react"




export default function PartTypetag({type='N/A'}) {
    const getColorByType = (type) => {
        if (type && typeof type === 'string')
            switch (type.toLowerCase()) {
                case 'part':
                    return 'blue'
                case 'assembly':
                    return 'green'
                case 'subassembly':
                    return 'purple'
                case 'kit':
                    return 'orange'
                case 'raw':
                    return 'red'
                case 'consumable':
                    return 'teal'
                case 'tool':
                    return 'cyan'
                case 'material':
                    return 'pink'
                case 'other':
                    return 'gray'
                default:
                    return 'gray'
            }
        return 'gray'
    }
    return (<Tag variant='outline' colorScheme={getColorByType(type)} width={'fit-content'} p={1}>
        <Text as="h6" m={0} fontSize="sm" textTransform={'capitalize'} >
            {type || 'N/A'}
        </Text>
    </Tag>)
}
  

    
   