import { Box, Center, Container, Heading, HStack, Table,  TableContainer, Tag, Tbody, Td, Text, Thead, Tr, useColorMode, VStack } from "@chakra-ui/react";
import DueDateIcon from "@components/icons/DueDateIcon";
import StatusIcon from "@components/icons/StatusIcon";
import PartTypetag from "@components/tags/PartTypeTag";
import { tokenConfig } from "@redux/actions/auth-actions/tokenConfig";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";






export const TravelersDangerZone = () => {
    const { colorMode } = useColorMode();
    const [travelerDangerZone, setTravelerDangerZone] = useState([])
    const navigate = useNavigate()


    const handleSelectTraveler = (_id) => {
        navigate(`/travelers/${_id}`)
    }

    const handleFecthDangerZone = async () => {
        try {
            const token = await tokenConfig({
                action: 'inactive',
            })
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/traveler/danger-zone`, token).then(({ data }) => {
                setTravelerDangerZone(data?.travelers || [])
            }).catch((err) => {
                toast.error(err?.message || 'Error fetching data')
                console.log(err)
            })

        } catch (error) {
            toast.error(error?.message || 'Error fetching data')
            console.log(error)
        }

    }

    const hanldeGetDaysInactivity = (item) => {
        const lastActivity = new Date(item?.updatedAt)
        const currentDate = new Date()
        const diffTime = Math.abs(currentDate - lastActivity);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays
    }


    useEffect(() => {
        handleFecthDangerZone()
    }
        , [])
    return (<Box minH={'100vh'}>
    
        <Center  mt={5} pt={5}>
            <Heading color={colorMode === 'light' ? 'red.500' : 'red.300'} size={'md'}>Danger-Zone (Travelers)</Heading>
        </Center>

        <Container p={1} w={'full'} maxW={{ base: 'full', md: '65vw', xl: '90vw', '2xl': '70vw' }}>

            <TableContainer shadow={'md'} maxW={'90vw'}  rounded={'md'} >
                <Table shadow={'sm'}>
                    <Thead fontFamily={'sans-serif'} textTransform={'uppercase'} fontWeight={'bold'} textColor={colorMode === 'dark' ? 'gray.300' : 'gray.700'}
                        color={colorMode === 'dark' ? 'white' : 'gray.700'}
                        bg={colorMode === 'dark' ? 'linear-gradient(181deg, rgb(2, 0, 97) 15%, rgb(97, 149, 219) 158.5%);' : 'gray.100'} >
                        <Tr>
                            <Td>
                                <VStack alignItems={'center'}>
                                    <Text>Status</Text>
                                    <Text>T#</Text>
                                </VStack>
                            </Td>
                            <Td>PO#</Td>
                            <Td >
                                <VStack alignItems={'start'}>
                                    <Text>Type</Text>
                                    <Text>Part#</Text>
                                </VStack>
                            </Td>
                            <Td>
                                <VStack alignItems={'end'}>
                                    <Text>Due Date</Text>
                                    <Text>Last Update</Text>
                                </VStack>
                            </Td>

                        </Tr>
                    </Thead>
                    <Tbody fontWeight={'bold'} bg={colorMode === 'dark' ? 'gray.700' : 'gray.300'} color={
                        colorMode === 'dark' ? 'white' : 'black'
                    }>

                        {travelerDangerZone?.map((item, index) => <Tr key={index} cursor={'pointer'} onClick={() => handleSelectTraveler(item?._id)} >
                            <Td>
                                <VStack alignItems={'start'}>
                                    <StatusIcon status={item?.status?.stage} />
                                    <Text>{item.id}</Text>
                                </VStack>
                            </Td>
                            <Td textTransform={'uppercase'}>{item.po}</Td>
                            <Td textTransform={'uppercase'}>
                                <VStack alignItems={'start'}>
                                    <PartTypetag type={item?.payload_part?.type} />
                                    <Text>{item?.payload_part?.id}</Text>
                                </VStack>
                            </Td>
                            <Td>
                                <VStack alignItems={'end'}>
                                    <HStack alignItems={'center'}>
                                        <DueDateIcon stage={item?.status.stage} due={item.payload_due} />
                                        <Text>{new Date(item.payload_due)?.toLocaleDateString()}</Text>
                                    </HStack>
                                    <HStack alignItems={'center'}>
                                        <Tag colorScheme={hanldeGetDaysInactivity(item) > 30 ? 'red' : 'yellow'}>
                                            <HStack spacing={0}>
                                                <Text fontWeight={'bold'}>{hanldeGetDaysInactivity(item)}</Text>
                                                <Text fontSize={'xs'}>days</Text>
                                            </HStack>
                                        </Tag>
                                        <Text>{new Date(item.updatedAt)?.toLocaleDateString()}</Text>

                                    </HStack>
                                </VStack>
                            </Td>




                        </Tr>)}

                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    </Box>);
}
