
export const getColorByStatus = (status, colorMode) => {
    if (status && typeof status === 'string')
        switch (status.toLowerCase()) {
            case 'launched':
                return (colorMode === 'light' ? '#46afe8' : '#117bbd')
            case 'hold':
                return (colorMode === 'light' ? '#46afe8' : '#117bbd')
            case 'processing':
                return (colorMode === 'light' ? '#36a858' : '#1dab5f')
            case 'completed':
                return (colorMode === 'light' ? '#36a858' : '#1dab5f')
            case 'returned':
                return (colorMode === 'light' ? '#de184a' : '#ad2b41')
            case 'disabled':
                return (colorMode === 'light' ? '#c9970e' : '#fcba03')
            case 'canceled':
                return (colorMode === 'light' ?'#de184a' : '#ad2b41')
            case 'qc':
                return (colorMode === 'light' ? '#36a858' : '#1dab5f')
            case 'shipped':
                return (colorMode === 'light' ? '#36a858' : '#1dab5f')
            default:
                return ('none')

        }
    return ('none')
}